import React from 'react'
import { Checkbox } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import styles from './groupTree.module.css'
import { useCheckboxLogic } from './hooks/useCheckBox'

const useStyles = makeStyles({
  root: {
    padding: 0,
  },
  icon: {
    width: 18,
    height: 18,
    borderRadius: 3,
    backgroundColor: '#fff',
    border: '1px solid #b0b0b0',
  },
  checkedIcon: {
    width: 18,
    height: 18,
    padding: 0,
    borderRadius: 3,
    backgroundColor: '#007bff',
    border: '1px solid #007bff',
  },
  partialCheckedIcon: {
    width: 18,
    height: 18,
    padding: 0,
    borderRadius: 3,
    backgroundColor: '#fff',
    border: '3px solid #007bff',
  },
  treeNodeContent: {
    cursor: 'pointer',
    padding: '5px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  checkboxWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginRight: '20px',
  },
})

const TreeNode = ({
  group,
  isOpen,
  onToggle,
  onSelect,
  style,
  withCheckboxes,
  onCheckboxChange,
  checked,
  hasChildrenChecked,
}) => {
  const classes = useStyles()

  const {
    handleLabelClick,
    handleCheckboxClick,
    icon,
    checkedIcon,
  } = useCheckboxLogic({
    group,
    hasChildrenChecked,
    checked,
    onCheckboxChange,
    onSelect,
    withCheckboxes,
    classes,
  })

  return (
    <div
      className={styles.treeNode}
      style={{
        paddingLeft: `${group.level * 20}px`,
        ...style,
      }}
    >
      <div className={classes.treeNodeContent}>
        {group.children.length > 0 && (
          <div
            onClick={() => onToggle(group.oid)}
            className={styles.treeToggle}
          >
            {isOpen ? '▼' : '►'}
          </div>
        )}
        <div
          onClick={handleLabelClick}
          style={{ display: 'flex', flexGrow: 1 }}
        >
          <span className={styles.treeNodeLabel}>{group.name}</span>
        </div>
        {withCheckboxes && (
          <div className={classes.checkboxWrapper}>
            <Checkbox
              checked={checked}
              onChange={handleCheckboxClick}
              onClick={e => e.stopPropagation()}
              icon={icon}
              checkedIcon={checkedIcon}
              classes={{ root: classes.root }}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default React.memo(TreeNode)
