import React from 'react'
import { connect } from 'redux-zero/react'
import actions from '../../../store/actions'
import Button from '../Buttons/Button'
import styled from 'styled-components'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import WarningIcon from '../../../assets/svg-icons/warning.svg'
import { deleteGroup } from '../../../services/api'
import Keys from '../../../utils/Keys'
const StyledModal = styled(Modal)``

const DeleteGroupWarning = props => {
  const close = () => {
    props.setShowDeleteGroupWarningModal(false)
  }

  const handleResponse = response => {
    let msg = 'YA! This group is gone!'
    let alertType = Keys.ALERT_SUCCESS
    if (response.status !== 200) {
      msg = 'failed to add delete the Group :0 , please check with R&D team'
      alertType = Keys.ALERT_ERROR
    }
    props.handleAlert({
      text: msg,
      type: alertType,
    })
    props.setShowGroupModal(false)
    props.hideFullScreenLoader()
  }

  const handleDelete = () => {
    let { groups, selectedGroup, selected_rid } = props
    props.showFullScreenLoader()
    deleteGroup(handleResponse, {
      rid: selected_rid,
      oid: selectedGroup[Keys.OBJECT_ID],
    })

    delete groups[selectedGroup[Keys.OBJECT_ID]]
    props.setGroups({ ...groups })
    props.setEditGroup(false)
    close()
  }

  return (
    <StyledModal isOpen={props.showDeleteGroupWarningModal} toggle={close}>
      <ModalHeader toggle={close}>
        <img className="modalHeaderImage" src={WarningIcon} /> Delete Group
      </ModalHeader>
      <ModalBody>
        <h4 className="modalHeaderText">
          Are you sure you want to delete : {props.selectedGroup[Keys.NAME]}
        </h4>
        <div className="modalButtonDash">
          <Button
            label={'Yes, delete please'}
            type="submit"
            onClick={handleDelete}
          />

          <Button
            type="button"
            onClick={close}
            color={'secondary'}
            label={'Cancel'}
          />
        </div>
      </ModalBody>
    </StyledModal>
  )
}

const mapStateToProps = ({
  showGroupModal,
  selectedGroup,
  showDeleteGroupWarningModal,
  editGroup,
  groups,
  selected_rid,
}) => ({
  showGroupModal,
  selectedGroup,
  showDeleteGroupWarningModal,
  editGroup,
  groups,
  selected_rid,
})
export default connect(
  mapStateToProps,
  actions
)(DeleteGroupWarning)
