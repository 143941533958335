import React from 'react'
import { connect } from 'redux-zero/react'
import actions from '../../../store/actions'
import FullScreenModal from '../FullScreenModal'
import Keys from '../../../utils/Keys.json'
import GroupForm from '../Form/GroupForm'
import DeleteGroupWarning from '../DeleteWarning/DeleteGroupWarning'

const GroupModal = props => {
  const close = () => {
    props.setShowGroupModal(false)
    props.setEditGroup(false)
    props.setSelectedGroup({})
  }

  return (
    <FullScreenModal
      isOpen={props.showGroupModal}
      close={close}
      header={
        props.editGroup
          ? 'Edit Group Id: ' + props.selectedGroup[Keys.OBJECT_ID]
          : 'New Group'
      }
    >
      <GroupForm
        closeModal={close}
        {...props}
        editUser={props.editGroup}
        selectedGroup={props.selectedGroup}
      />
      <DeleteGroupWarning />
    </FullScreenModal>
  )
}

const mapStateToProps = ({
  selectedUser,
  showGroupModal,
  selectedGroup,
  showDeleteUserWarningModal,
  editGroup,
}) => ({
  selectedUser,
  showGroupModal,
  selectedGroup,
  showDeleteUserWarningModal,
  editGroup,
})
export default connect(mapStateToProps, actions)(GroupModal)
