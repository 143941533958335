import React, { useCallback, useMemo } from 'react'

export const useCheckboxLogic = ({
  group,
  hasChildrenChecked,
  checked,
  onCheckboxChange,
  onSelect,
  withCheckboxes,
  classes,
}) => {
  const handleLabelClick = useCallback(() => {
    if (withCheckboxes) {
      onCheckboxChange(group.oid)
    } else {
      onSelect(group)
    }
  }, [withCheckboxes, onCheckboxChange, group.oid, onSelect])

  const handleCheckboxClick = useCallback(
    event => {
      event.stopPropagation()
      onCheckboxChange(group.oid)
    },
    [onCheckboxChange, group.oid]
  )

  const icon = useMemo(() => {
    if (hasChildrenChecked && !checked) {
      return <span className={classes.partialCheckedIcon} />
    }
    return <span className={classes.icon} />
  }, [hasChildrenChecked, checked, classes])

  const checkedIcon = useMemo(() => <span className={classes.checkedIcon} />, [
    classes,
  ])

  return {
    handleLabelClick,
    handleCheckboxClick,
    icon,
    checkedIcon,
  }
}
