import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import Chip from '@material-ui/core/Chip'
import styled from 'styled-components'
import isNil from 'lodash/isNil'
import { makeStyles } from '@material-ui/core/styles'
import { indigo, red } from '@material-ui/core/colors'
import SvgIcon from '@material-ui/core/SvgIcon'

const useStyles = makeStyles(theme => ({
  root: {
    '& > svg': {
      margin: theme.spacing(2),
    },
  },
}))

const MyChip = styled(Chip)`
  margin: 2px;
`
const GroupChip = props => {
  return (
    <MyChip
      size="small"
      color={!isNil(props.id) ? 'primary' : 'secondary'}
      variant="outlined"
      label={!isNil(props.label) ? props.label : 'Undefined'}
      onClick={() => props.onClick(props.id)}
    />
  )
}

export default GroupChip
