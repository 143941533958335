import { useState, useCallback } from 'react'
export const useCheckedGroups = (groups, openGroups) => {
  const [checkedGroups, setCheckedGroups] = useState({})

  const handleCheckboxChange = useCallback(
    groupId => {
      const isOpen = openGroups[groupId]

      const selectOrDeselectAllChildren = (node, select) => {
        let affectedIds = [node.oid]
        if (node.child && node.child.length > 0) {
          node.child.forEach(childOid => {
            const childNode = groups[childOid]
            if (childNode) {
              affectedIds = affectedIds.concat(
                selectOrDeselectAllChildren(childNode, select)
              )
            }
          })
        }
        return affectedIds
      }

      setCheckedGroups(prevCheckedGroups => {
        const newCheckedGroups = { ...prevCheckedGroups }
        const node = groups[groupId]
        const affectedNodes = selectOrDeselectAllChildren(
          node,
          !newCheckedGroups[groupId]
        )

        if (isOpen) {
          if (newCheckedGroups[groupId]) {
            delete newCheckedGroups[groupId]
          } else {
            newCheckedGroups[groupId] = true
          }
        } else {
          const allSelected = affectedNodes.every(id => newCheckedGroups[id])
          affectedNodes.forEach(id => {
            if (allSelected) {
              delete newCheckedGroups[id]
            } else {
              newCheckedGroups[id] = true
            }
          })
        }

        return newCheckedGroups
      })
    },
    [groups, openGroups]
  )

  return { checkedGroups, handleCheckboxChange }
}
