import { useCallback, useState } from 'react'
export const useOpenGroups = (initialState = {}) => {
  const [openGroups, setOpenGroups] = useState(initialState)

  const toggleGroup = useCallback(groupId => {
    setOpenGroups(prevState => ({
      ...prevState,
      [groupId]: !prevState[groupId],
    }))
  }, [])

  return { openGroups, toggleGroup }
}
