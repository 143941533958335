import React from 'react'
import { Button } from 'react-bootstrap'
import ExcelJS from 'exceljs'

export const ExportGroupsToCSV = ({ dataObject, rid, selectedGroup, tags }) => {
  const handleExport = async () => {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('Sheet 1')
    // Set column headers
    worksheet.columns = [
      { header: 'Name', key: 'name', width: 35 },
      { header: 'ID', key: 'oid', width: 45 },
      { header: 'SID', key: 'sid', width: 30 },
      { header: 'Parent', key: 'parent', width: 45 },
      { header: 'Children', key: 'child', width: 45 },
      { header: 'UIDs', key: 'uids', width: 45 },
      { header: 'Tags', key: 'tags', width: 45 },
      { header: 'Tags names', key: 'tagNames', width: 45 },
    ]

    // Add data rows
    let rowCount = 0
    Object.values(dataObject).forEach(data => {
      // Check if the maximum row count has been reached
      if (rowCount >= Object.values(dataObject).length) {
        return
      }

      worksheet.addRow({
        name: data.name,
        oid: data.oid,
        sid: data.sid?.join('\n'),
        parent: data.parent,
        child: data.child.join('\n'),
        uids: data.uids.join('\n'),
        tags: tags
          .filter(tag => data.tags.includes(tag.sk))
          .map(tag => tag.sk)
          .join('\n'),
        tagNames: tags
          .filter(tag => data.tags.includes(tag.sk))
          .map(tag => tag.name)
          .join('\n'),
      })

      rowCount++
    })

    worksheet.eachRow(row => {
      row.eachCell(cell => {
        cell.alignment = {
          wrapText: true,
          vertical: 'top',
          horizontal: 'center',
        }
        cell.font = { size: 14 }
      })
    })

    // Set fill color for first column
    const firstColumn = worksheet.getColumn(1)
    firstColumn.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFC0C0C0' }, // Light gray color
    }
    firstColumn.border = {
      top: { style: 'thin', color: { argb: 'FF000000' } }, // Black color
      left: { style: 'thin', color: { argb: 'FF000000' } },
      bottom: { style: 'thin', color: { argb: 'FF000000' } },
      right: { style: 'thin', color: { argb: 'FF000000' } },
    }
    firstColumn.font = { bold: true, size: 16, color: { argb: 'FF000000' } }

    // Set fill color and border for header row
    const headerRow = worksheet.getRow(1)
    headerRow.eachCell(cell => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFC0C0C0' },
      }

      // Set font style for header
      cell.font = { bold: true, size: 16, color: { argb: 'FF000000' } }

      // Add border to the cell
      cell.border = {
        top: { style: 'thin', color: { argb: 'FF000000' } }, // Black color
        left: { style: 'thin', color: { argb: 'FF000000' } },
        bottom: { style: 'thin', color: { argb: 'FF000000' } },
        right: { style: 'thin', color: { argb: 'FF000000' } },
      }
    })

    // Increase header row height
    headerRow.height = 30

    // Generate buffer
    const buffer = await workbook.xlsx.writeBuffer()

    // Create blob and download
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = selectedGroup
      ? `${rid}-${selectedGroup}.xlsx`
      : `${rid}.xlsx`
    link.click()

    // Cleanup
    URL.revokeObjectURL(url)
  }

  return (
    <Button
      variant="primary"
      onClick={handleExport}
      style={{
        border: '1px solid #b7b7ff',
        borderRadius: '10px',
        color: '#b7b7ff',
      }}
    >
      Export to CSV
    </Button>
  )
}
