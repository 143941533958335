import React, { useState, useEffect } from 'react'
import Input from '../FormElements/Input'
import GroupChip from './GroupChip'
const GroupSearch = props => {
  const [searchQuery, setSearchQuery] = useState('')
  const [searchGroups, setSearchGroups] = useState([])

  const groupsToTreeData = () => {
    const { groups } = props
    let currentSearchedGroups = []
    Object.keys(groups).forEach(group_oid => {
      const group = groups[group_oid]
      const node = { id: group.oid, parentId: group.parent, label: group.name }
      if (
        searchQuery !== '' &&
        (node.id.includes(searchQuery) || node.label.includes(searchQuery))
      ) {
        currentSearchedGroups.push(node)
      }
    })
    setSearchGroups(currentSearchedGroups)
  }

  useEffect(() => {
    groupsToTreeData()
  }, [searchQuery])

  const onChipClick = group_id => {
    props.setSelectedGroup({
      ...props.groups[group_id],
    })
    props.setEditGroup(true)
    props.setShowGroupModal(true)
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItem: 'baseline',
      }}
      align={'left'}
    >
      <Input
        onChange={event => {
          setSearchQuery(event.target.value)
        }}
        label={'search'}
        value={searchQuery}
      />
      {searchGroups.map(group => {
        return <GroupChip onClick={onChipClick} {...group} />
      })}
    </div>
  )
}
export default GroupSearch
