export const buildTree = groups => {
  const groupMap = {}

  Object.keys(groups).forEach(oid => {
    groupMap[oid] = { ...groups[oid], children: [] }
  })

  Object.keys(groupMap).forEach(oid => {
    const group = groupMap[oid]
    if (group.parent && groupMap[group.parent]) {
      groupMap[group.parent].children.push(group)
    }
  })

  return Object.values(groupMap).filter(group => !group.parent)
}

export const flattenTree = (nodes, openGroups, level = 0) => {
  let result = []
  nodes.forEach(node => {
    result.push({ ...node, level })
    if (openGroups[node.oid] && node.children.length > 0) {
      result = result.concat(flattenTree(node.children, openGroups, level + 1))
    }
  })
  return result
}
