import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react'
import { FixedSizeList as List } from 'react-window'
import { buildTree, flattenTree } from './utils'
import TreeNode from './TreeNode'
import { useOpenGroups } from './hooks/useOpenGroups'
import { useCheckedGroups } from './hooks/useCheckedGroups'
import { useHasCheckedChildren } from './hooks/useHasCheckedChildren'
import styles from './virtualizedGroupTree.module.css'

const VirtualizedGroupTree = ({
  groups,
  setSelectedGroup,
  setEditGroup,
  setShowGroupModal,
  onSelectedGroupsChange,
  withCheckboxes = false,
  listHeight = 600,
  itemSize = 35,
}) => {
  const [treeNodes, setTreeNodes] = useState([])
  const listRef = useRef()
  const [isTopVisible, setTopVisible] = useState(false)
  const [isBottomVisible, setBottomVisible] = useState(false)

  const { openGroups, toggleGroup } = useOpenGroups()
  const { checkedGroups, handleCheckboxChange } = useCheckedGroups(
    groups,
    openGroups
  )

  const hasCheckedChildren = useHasCheckedChildren(groups, checkedGroups)

  const treeData = useMemo(() => buildTree(groups), [groups])

  useMemo(() => {
    const flattened = flattenTree(treeData, openGroups)
    setTreeNodes(flattened)
  }, [treeData, openGroups])

  const handleSelectGroup = useCallback(
    group => {
      if (!withCheckboxes) {
        setSelectedGroup({ ...groups[group.oid] })
        setEditGroup(true)
        setShowGroupModal(true)
      }
    },
    [withCheckboxes, groups, setSelectedGroup, setEditGroup, setShowGroupModal]
  )

  useEffect(() => {
    if (withCheckboxes && onSelectedGroupsChange) {
      const selectedGroupIds = Object.keys(checkedGroups)
      onSelectedGroupsChange(selectedGroupIds)
    }
  }, [checkedGroups, withCheckboxes, onSelectedGroupsChange])

  useEffect(() => {
    const checkListSize = () => {
      const totalHeight = treeNodes.length * itemSize
      if (totalHeight > listHeight) {
        setBottomVisible(true)
      } else {
        setBottomVisible(false)
      }
    }

    checkListSize()
  }, [treeNodes, itemSize, listHeight])

  const handleScroll = ({ scrollOffset, scrollHeight }) => {
    const visibleHeight = listHeight

    setTopVisible(scrollOffset > 0)

    const isAtBottom = scrollOffset + visibleHeight >= scrollHeight
    setBottomVisible(!isAtBottom)
  }

  const renderRow = useCallback(
    ({ index, style }) => {
      const group = treeNodes[index]
      const hasCheckedChild = hasCheckedChildren(group)

      return (
        <div style={style}>
          <TreeNode
            key={group.oid}
            group={group}
            isOpen={!!openGroups[group.oid]}
            onToggle={toggleGroup}
            onSelect={handleSelectGroup}
            withCheckboxes={withCheckboxes}
            onCheckboxChange={handleCheckboxChange}
            checked={!!checkedGroups[group.oid]}
            hasChildrenChecked={hasCheckedChild}
          />
        </div>
      )
    },
    [
      treeNodes,
      openGroups,
      toggleGroup,
      handleSelectGroup,
      withCheckboxes,
      handleCheckboxChange,
      checkedGroups,
      hasCheckedChildren,
    ]
  )

  return (
    <div className={styles.virtualizedListContainer}>
      {isTopVisible && <div className={styles.shadowTop} />}
      {isTopVisible && (
        <div className={`${styles.arrow} ${styles.arrowTop}`}>↑</div>
      )}
      <List
        ref={listRef}
        height={listHeight}
        itemCount={treeNodes.length}
        itemSize={itemSize}
        width="100%"
        onScroll={handleScroll}
      >
        {renderRow}
      </List>
      {isBottomVisible && <div className={styles.shadowBottom} />}
      {isBottomVisible && (
        <div className={`${styles.arrow} ${styles.arrowBottom}`}>↓</div>
      )}
    </div>
  )
}

export default VirtualizedGroupTree
