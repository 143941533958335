export const useHasCheckedChildren = (groups, checkedGroups) => {
  const hasCheckedChildrenRecursive = group => {
    if (group.child && group.child.length > 0) {
      return group.child.some(childOid => {
        const childGroup = groups[childOid]
        return (
          checkedGroups[childOid] ||
          (childGroup && hasCheckedChildrenRecursive(childGroup))
        )
      })
    }
    return false
  }

  return hasCheckedChildrenRecursive
}
